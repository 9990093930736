import perlinNoise3d from 'perlin-noise-3d'

const canvas = document.getElementById('stage')
const context = canvas.getContext('2d')
let canvasWidth = canvas.getBoundingClientRect().width
let canvasHeight = canvas.getBoundingClientRect().height

const noiseCanvas = document.getElementById('noise')
const noiseContext = noiseCanvas.getContext('2d')
let noiseCanvasWidth = noiseCanvas.getBoundingClientRect().width
let noiseCanvasHeight = noiseCanvas.getBoundingClientRect().height

const dpr = window.devicePixelRatio || 1

const STAGE_WIDTH = 600
const STAGE_HEIGHT = 800
const NUM_ROWS = 10
const NUM_COLS = 6
const CELL_WIDTH = Math.floor(STAGE_WIDTH / NUM_COLS)
const CELL_HEIGHT = Math.floor(STAGE_HEIGHT / NUM_ROWS)
const BUBBLE_SIZE_MIN = 0
const BUBBLE_SIZE_MAX = 100
const SAMPLE_RATE_MIN = 200
const SAMPLE_RATE_MAX = 600

let showNoise = false

let bubbles = []
let bubbles2d = []

const getRandom = (min, max) => {
  return Math.random() * (max - min) + min
}

const scaleValue = (value, from, to) => {
  const scale = (to[1] - to[0]) / (from[1] - from[0])
  const capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
  return ~~(capped * scale + to[0])
}

class Bubble {
  constructor(x, y, size, sampleRate) {
    this.x = x
    this.y = y
    this.size = size
    this.targetSize = size
    this.lastTime = 0
    this.tickTime = window.performance.now()
    this.sampleRate = sampleRate
  }

  setTargetSize(targetSize) {
    this.tickTime = window.performance.now()
    this.targetSize = targetSize
  }

  update(tick) {
    const centerX = this.x
    const centerY = this.y

    let circle = new Path2D()
    circle.arc(centerX, centerY, this.size, 0, 2 * Math.PI, false)

    context.fillStyle = 'rgba(0, 0, 0, 1)'
    context.fill(circle)

    this.size = scaleValue(Math.abs(tick - this.tickTime), [0, this.sampleRate], [this.size, this.targetSize])

    if (this.size < BUBBLE_SIZE_MIN) {
      this.size = BUBBLE_SIZE_MIN
    }

    if (this.size > BUBBLE_SIZE_MAX) {
      this.size = BUBBLE_SIZE_MAX
    }
  }
}

const run = () => {
  const noise = new perlinNoise3d()
  noise.noiseSeed(Math.random())

  const noiseXFactor = 1000
  const noiseYFactor = 1000
  const noiseZFactor = 1000

  const drawScene = (time) => {
    context.clearRect(0, 0, canvasWidth, canvasHeight)
    noiseContext.clearRect(0, 0, noiseCanvasWidth, noiseCanvasHeight)

    if (showNoise) {
      const startX = Math.floor(canvasWidth / 2 - STAGE_WIDTH / 2)
      const startY = Math.floor(canvasHeight / 2 - STAGE_HEIGHT / 2)

      for (let x = startX; x < STAGE_WIDTH + startX; x++) {
        for (let y = startY; y < STAGE_HEIGHT + startY; y++) {
          const value = noise.get(x / noiseXFactor, y / noiseYFactor, time / noiseZFactor)

          if (bubbles2d[x] && bubbles2d[x][y]) {
            const size = Math.floor(scaleValue(value, [0, 1], [BUBBLE_SIZE_MIN, BUBBLE_SIZE_MAX]))

            bubbles2d[x][y].setTargetSize(size)
          }

          const color = scaleValue(value, [0, 1], [0, 255])
          noiseContext.fillStyle = `rgb(${color}, ${color}, ${color})`
          noiseContext.fillRect(x, y, 1, 1)
        }
      }
    }

    for (let i = 0; i < bubbles.length; i++) {
      const bubble = bubbles[i]

      if (!bubble.lastTime || time - bubble.lastTime >= bubble.sampleRate) {
        bubble.lastTime = time

        const value = noise.get(bubble.x / noiseXFactor, bubble.y / noiseYFactor, time / noiseZFactor)
        const size = Math.floor(scaleValue(value, [0, 1], [BUBBLE_SIZE_MIN, BUBBLE_SIZE_MAX]))

        bubble.setTargetSize(size)
      }

      bubble.update(time)
    }

    window.requestAnimationFrame(drawScene)
  }

  window.requestAnimationFrame(drawScene)
}

const build = () => {
  bubbles = []
  bubbles2d = []

  const offsetX = canvasWidth / 2 - STAGE_WIDTH / 2
  const offsetY = canvasHeight / 2 - STAGE_HEIGHT / 2

  for (let i = 0; i < NUM_COLS; i++) {
    for (let j = 0; j < NUM_ROWS; j++) {
      const bubbleX = Math.floor(i * CELL_WIDTH + CELL_WIDTH / 2 + offsetX)
      const bubbleY = Math.floor(j * CELL_HEIGHT + CELL_HEIGHT / 2 + offsetY)

      const bubble = new Bubble(
        bubbleX,
        bubbleY,
        getRandom(BUBBLE_SIZE_MIN, BUBBLE_SIZE_MAX),
        getRandom(SAMPLE_RATE_MIN, SAMPLE_RATE_MAX)
      )

      bubbles.push(bubble)

      if (bubbles2d[bubbleX]) {
        bubbles2d[bubbleX][bubbleY] = bubble
      } else {
        bubbles2d[bubbleX] = [bubble]
      }
    }
  }
}

const setupCanvas = (canvas) => {
  const rect = canvas.getBoundingClientRect()
  canvas.width = rect.width * dpr
  canvas.height = rect.height * dpr

  const ctx = canvas.getContext('2d')
  ctx.scale(dpr, dpr)
}

const init = () => {
  const colors = [
    '#ffc600', // 'Young Mango'
    '#ff8672', // 'Extra Peach'
    '#dcc7b7', // 'Toasted Coconut'
    '#006098', // 'Sour Blueberry'
    '#752e4a', // 'Blackberry Jam'
    '#ddb5c8', // 'White Grape'
    '#ffd720', // 'Lemon Verbena'
    '#93d500', // 'Pear Elderflower'
    '#f4436c', // 'Strawberry Basil'
    '#ff8300', // 'Orange Nectarine'
    '#4a9462', // 'Gingery Ale'
    '#db0032', // 'Cherry Pop'
  ]

  document.documentElement.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)]

  const urlParams = new URLSearchParams(window.location.search)
  showNoise = urlParams.get('noise')

  setupCanvas(canvas)
  setupCanvas(noiseCanvas)
  build()
  run()
}

init()
